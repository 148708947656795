import React, { FC, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { Event } from '../types';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import { DeleteDialog } from './DeleteDialog';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';

type Props = {
  inputEvent: Event;
  topicId: number;
  fetchTopics: () => void;
  cancelAddEvent: () => void;
  showToast: (message: string) => void;
};

export const EventRow: FC<Props> = ({
  inputEvent,
  topicId,
  fetchTopics,
  cancelAddEvent,
  showToast,
}) => {
  const [event, setEvent] = useState<Event>(inputEvent);
  const [open, setOpen] = useState(event.key.length === 0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const deleteEvent = async () => {
    setOpenDeleteDialog(false);
    const response = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}topic/${topicId}/event/${event.id}/`
    );
    if (response.status >= 200 && response.status < 300) {
      showToast(`Event "${event.key}" succesvol verwijderd`);
      fetchTopics();
    } else {
      console.error(response);
    }
  };

  const setActive = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}topic/${topicId}/event/${event.id}/active/`
    );
    if (response.status >= 200 && response.status < 300) {
      setEvent({ ...event, active: !event.active });
    } else {
      console.error(response);
    }
  };

  const addEvent = async () => {
    if (event.key.length !== 0 && event.message.length !== 0) {
      const response = await axios.post<Event>(
        `${process.env.REACT_APP_BACKEND_URL}topic/${topicId}/event/`,
        {
          key: event.key,
          message: event.message,
          active: event.active,
        }
      );
      showToast(`Event "${response.data.key}" succesvol toegevoegd`);
      fetchTopics();
    }
  };

  const editEvent = async () => {
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}topic/${topicId}/event/${event.id}/`, {
      key: event.key,
      message: event.message,
      active: event.active,
    });
    showToast('Wijzigingen opgeslagen');
  };

  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
        <Collapse in={true} timeout="auto" unmountOnExit>
          <Box sx={{ marginLeft: 4 }}>
            <Table size="small" aria-label="events">
              <TableBody>
                <TableRow sx={{ '& > *': { marginLeft: 10 } }}>
                  <TableCell align="left" sx={{ width: '120px' }}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                      {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    <Switch onChange={setActive} color={'success'} checked={event.active} />
                  </TableCell>
                  <TableCell align="left">{event.key}</TableCell>
                  <TableCell align="right" sx={{ paddingRight: '0px' }}>
                    {event.id === 0 ? (
                      <Button
                        sx={{ marginLeft: 6 }}
                        variant="outlined"
                        color="error"
                        onClick={cancelAddEvent}
                        startIcon={<CancelIcon />}
                      >
                        Cancel
                      </Button>
                    ) : (
                      <Button onClick={() => setOpenDeleteDialog(true)}>
                        <DeleteIcon sx={{ color: '#000000' }} />
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      <Box sx={{ margin: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <TextField
                              onChange={(e) => setEvent({ ...event, key: e.target.value })}
                              fullWidth
                              id="outlined-basic"
                              label="Event"
                              variant="outlined"
                              defaultValue={event.key}
                              autoFocus={event.key.length === 0}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            {event.id === 0 ? (
                              <Button
                                onClick={addEvent}
                                sx={{ height: '100%' }}
                                fullWidth
                                variant="contained"
                                color="success"
                                startIcon={<AddIcon fontSize="large" />}
                              >
                                Add event
                              </Button>
                            ) : (
                              <Button
                                onClick={editEvent}
                                sx={{ height: '100%' }}
                                variant="contained"
                                fullWidth
                                startIcon={<SaveIcon fontSize="large" />}
                              >
                                Save event
                              </Button>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              onChange={(e) => setEvent({ ...event, message: e.target.value })}
                              margin="normal"
                              id="outlined-multiline-static"
                              label="JSON"
                              multiline
                              rows={10}
                              fullWidth
                              defaultValue={event.message}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
      <DeleteDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        name={event.key}
        deleteFunc={deleteEvent}
      />
    </TableRow>
  );
};

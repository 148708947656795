import React, { FC } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

type Props = {};

export const ActivitySkeleton: FC<Props> = () => {
  return (
    <Grid item>
      <Card
        sx={{
          width: 600,
          maxWidth: 600,
          minHeight: 113.325,
          boxShadow: '0px 3px 5px grey',
        }}
      >
        <CardHeader
          avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
          title={<Skeleton animation="wave" height={15} width="20%" style={{ marginTop: 6 }} />}
          subheader={<Skeleton animation="wave" height={10} width="28%" style={{ marginTop: 6 }} />}
        />
        <CardContent sx={{ paddingBottom: 0, paddingTop: 0 }}>
          <Typography variant="body2" color="text.secondary">
            <Skeleton animation="wave" height={10} width="28%" style={{ marginTop: 6 }} />
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

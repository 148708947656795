import '../style/activityFeed.css';
import { useEffect, useRef, useState } from 'react';
import { ActivityCard } from './ActivityCard';
import { ActivityCardBigScreen } from './ActivityCardBigScreen';
import { Grid } from '@mui/material';
import { Activity } from '../types';
import { ActivitySkeleton } from './ActivitySkeleton';
import dayjs from 'dayjs';
import axios from 'axios';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { useSearchParams } from 'react-router-dom';

export const ActivityFeed = () => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const initializedSocket = useRef(false);
  const [params] = useSearchParams();
  const isBigScreen = params.get('big') === 'true';
  const emptyActivity = Array.from(Array(10).keys());
  const activitiesRecord: Record<string, Activity[]> = {};
  const formatTime = (timeCreated: string) => dayjs(timeCreated).toDate().toDateString();
  activities.map(
    (activity) =>
      (activitiesRecord[formatTime(activity.timeCreated)] = activitiesRecord[
        formatTime(activity.timeCreated)
      ]
        ? [...activitiesRecord[formatTime(activity.timeCreated)], activity]
        : [activity])
  );

  useEffect(() => {
    const socket = new SockJS(`${process.env.REACT_APP_BACKEND_URL!}ws`);
    const stompClient = Stomp.over(socket);

    if (!initializedSocket.current) {
      initializedSocket.current = true;
      stompClient.connect({}, () => {
        stompClient.subscribe('/topic/activities', (message) => {
          setActivities((old) => [JSON.parse(message.body) as Activity, ...old]);
        });
      });
    }

    return () => {
      if (stompClient.connected) stompClient.disconnect(() => {});
    };
  }, []);

  useEffect(() => {
    (async () => {
      const response = await axios.get<Activity[]>(
        `${process.env.REACT_APP_BACKEND_URL!}activity/`
      );
      setActivities(response.data);
    })();
  }, []);

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" item xs={12}>
      {activities.length <= 0 ? (
        <>
          <h1>There seems to be no activity</h1>
          <h2>Sit back and relax</h2>
          <img src={'assets/PNG/relax.png'} alt={'foto relax'} />
        </>
      ) : (
        <ul>
          <Grid container spacing={1} direction="column">
            {activities.length !== 0
              ? Object.entries(activitiesRecord).map(([k, v]) =>
                  v.map((a: Activity) =>
                    isBigScreen ? (
                      <ActivityCardBigScreen
                        isSeparated={v.indexOf(a) === 0}
                        key={a['id']}
                        activity={a}
                      />
                    ) : (
                      <ActivityCard isSeparated={v.indexOf(a) === 0} key={a['id']} activity={a} />
                    )
                  )
                )
              : emptyActivity.map((i) => <ActivitySkeleton key={i} />)}
          </Grid>
        </ul>
      )}
    </Grid>
  );
};

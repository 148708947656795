import { FC } from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { yellow } from '@mui/material/colors';
import { Grid, CardHeader, Card } from '@mui/material';
import dayjs from 'dayjs';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { Activity } from '../types';
import { Md5 } from 'ts-md5';
import '../style/activityCardBigScreen.css';

type Props = {
  activity: Activity;
  isSeparated: boolean;
};

export const ActivityCardBigScreen: FC<Props> = ({ activity, isSeparated }) => {
  const timeCreated = new Date(activity.timeCreated);
  const formattedTime = dayjs(timeCreated).format('HH:mm');
  const dayCreated = timeCreated.toDateString();

  const today = new Date().toDateString();
  const yesterday = dayjs().subtract(1, 'days').toDate().toDateString();

  //const theme = useTheme();
  //const activityCardSize = useMediaQuery(theme.breakpoints.up('sm'));

  const dateSeparator = isSeparated
    ? dayCreated === today
      ? 'Today'
      : dayCreated === yesterday
      ? 'Yesterday'
      : dayjs(timeCreated).format('ddd MMM D[th]')
    : null;

  return (
    <Grid item>
      {dateSeparator && <h4>{dateSeparator}</h4>}
      <Card className="activityCard">
        <CardHeader
          sx={{ paddingLeft: '7px', paddingTop: '7px', paddingBottom: '0px' }}
          avatar={
            activity.consultant ? (
              <Avatar
                alt={activity.consultant.firstName}
                src={`https://www.gravatar.com/avatar/${Md5.hashStr(
                  activity.consultant.email
                )}?s=100&r=g&d=retro`}
              />
            ) : (
              <Avatar sx={{ bgcolor: yellow['A200'] }} aria-label="recipe">
                <LightbulbIcon fontSize={'large'} />
              </Avatar>
            )
          }
          titleTypographyProps={{ variant: 'h5', fontWeight: 'bold', fontSize: 22 }}
          title={activity.title}
        />
        <Grid container justifyContent="space-between">
          <Typography sx={{ marginLeft: '65px' }} variant="h6" color="text.secondary">
            {activity.description}
          </Typography>
          <Typography sx={{ marginRight: '5px' }} variant="h6" color="text.secondary">
            {formattedTime}
          </Typography>
        </Grid>
      </Card>
    </Grid>
  );
};

import React, { FC } from 'react';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { yellow } from '@mui/material/colors';
import { useTheme, useMediaQuery, Grid, CardHeader, Card } from '@mui/material';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { Activity } from '../types';
import { Md5 } from 'ts-md5';

type Props = {
  activity: Activity;
  isSeparated: boolean;
};

export const ActivityCard: FC<Props> = ({ activity, isSeparated }) => {
  const timeCreated = new Date(activity.timeCreated);
  const formattedTime = dayjs(timeCreated).format('HH:mm');
  const dayCreated = timeCreated.toDateString();

  const today = new Date().toDateString();
  const yesterday = dayjs().subtract(1, 'days').toDate().toDateString();

  const theme = useTheme();
  const activityCardSize = useMediaQuery(theme.breakpoints.up('sm'));

  const classes = {
    activityCard: {
      width: 600,
      maxWidth: 600,
      minHeight: 113.325,
      boxShadow: '2px 4px 8px lightgrey',
    },
    activityCardMobile: {
      width: 350,
      maxWidth: 600,
      minHeight: 113.325,
      boxShadow: '2px 4px 8px lightgrey',
    },
  };

  const dateSeparator = isSeparated
    ? dayCreated === today
      ? 'Today'
      : dayCreated === yesterday
      ? 'Yesterday'
      : dayjs(timeCreated).format('ddd MMM D[th]')
    : null;

  return (
    <Grid item>
      {dateSeparator && <h4>{dateSeparator}</h4>}
      <Card sx={activityCardSize ? classes.activityCard : classes.activityCardMobile}>
        <CardHeader
          avatar={
            activity.consultant ? (
              <Avatar
                alt={activity.consultant.firstName}
                src={`https://www.gravatar.com/avatar/${Md5.hashStr(
                  activity.consultant.email
                )}?s=500&r=g&d=retro`}
              />
            ) : (
              <Avatar sx={{ bgcolor: yellow['A200'] }} aria-label="recipe">
                <LightbulbIcon fontSize={'large'} />
              </Avatar>
            )
          }
          titleTypographyProps={{ variant: 'h6', fontWeight: 'bold', fontSize: 18 }}
          title={activity.title}
          subheader={
            activity.consultant
              ? `${formattedTime} • ${activity.consultant.firstName}`
              : formattedTime
          }
        />
        <CardContent sx={{ paddingBottom: 0, paddingTop: 0 }}>
          <Typography variant="body2" color="text.secondary">
            {activity.description}
          </Typography>
        </CardContent>
        <Grid container justifyContent="flex-end">
          <CardActions disableSpacing sx={{ marginTop: -5 }}>
            <Button
              sx={{ color: '#FFFFFF', bgcolor: '#011d41' }}
              onClick={() =>
                window.parent.location.replace(
                  `${document.location.ancestorOrigins['0']}${activity.url}`
                )
              }
              variant="contained"
            >
              {activity.actionText}
            </Button>
          </CardActions>
        </Grid>
      </Card>
    </Grid>
  );
};

import { useEffect, useState } from 'react';
import './App.css';
import { ActivityFeed } from './components/ActivityFeed';
import { Route, Routes } from 'react-router-dom';
import { AdminPage } from './components/AdminPage';
import axios from 'axios';
import { CommentsPage } from './components/CommentsPage';
import { FileUploadPage } from './components/FileUploadPage';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const readyMessage = {
  messageType: 'ready',
  payload: {
    appName: 'collab',
  },
};

function App() {
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      const token = event.data.payload?.token;
      if (!token) return;
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setAccessToken(token);
    };

    if (!accessToken) window.parent.postMessage(readyMessage, '*');
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [accessToken]);

  return accessToken ? (
    <Routes>
      <Route path={'/activity'} element={<ActivityFeed />} />
      <Route path={'/admin'} element={<AdminPage accessToken={accessToken} />} />
      <Route path={'/comments'} element={<CommentsPage accessToken={accessToken} />} />
      <Route path={'/file-upload'} element={<FileUploadPage />} />
    </Routes>
  ) : (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '50%',
      }}
    >
      No access token
    </div>
  );
}

export default App;

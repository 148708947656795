import React, { FC, useCallback, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import { Topic, JWToken } from '../types';
import { ROLES } from '../constants';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import '../style/adminPage.css';
import CancelIcon from '@mui/icons-material/Cancel';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import jwtDecode from 'jwt-decode';
import { NoAccess } from './NoAccess';
import { Row } from './Row';

type Props = {
  accessToken: undefined | string;
};

export const AdminPage: FC<Props> = ({ accessToken }) => {
  const [topics, setTopics] = useState<null | Topic[]>(null);
  const [addTopic, setAddTopic] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [topicName, setTopicName] = useState('');

  const fetchTopics = useCallback(async () => {
    const response = await axios.get<Topic[]>(`${process.env.REACT_APP_BACKEND_URL}topic/`);
    setTopics(response.data);
  }, []);

  useEffect(() => {
    accessToken && fetchTopics();
  }, [accessToken, fetchTopics]);

  const resetTopicInput = () => {
    setAddTopic((prevState) => !prevState);
    setTopicName('');
  };

  const showToast = (message: string) => {
    setToastMessage(message);
    setOpenToast(true);
  };

  const saveTopic = async () => {
    if (topicName.length !== 0) {
      const response = await axios.post<Topic>(`${process.env.REACT_APP_BACKEND_URL}topic/`, {
        topic: topicName,
        active: true,
      });
      showToast(`Topic "${response.data.topic}" succesvol toegevoegd`);
      fetchTopics();
      resetTopicInput();
    }
  };

  return (
    <>
      {accessToken &&
      jwtDecode<JWToken>(accessToken)[ROLES].some((role: string) => role.includes('admin')) ? (
        <Grid m={8} container>
          <Typography align="center" variant="h2" /*gutterBottom*/ component="div" /*mt={4}*/>
            Manage notifications
          </Typography>
          <Button
            sx={{ width: 130, marginLeft: 5, ...(addTopic && { display: 'none' }) }}
            variant="contained"
            onClick={() => setAddTopic(true)}
          >
            Add topic
          </Button>
          <Typography
            paragraph={true}
            sx={{ width: '100%', mt: 2 }}
            align="left"
            component="div"
            variant="h5"
          >
            Here you can customize how notifications are handled
          </Typography>
          {Boolean(topics?.length) || addTopic ? (
            <TableContainer component={Paper} sx={{ marginTop: 4, border: 1 }}>
              <Table aria-label="collapsible table">
                <TableBody>
                  <TableRow
                    className={addTopic ? 'addTopicVisible' : 'addTopic'}
                    sx={{ '& > *': { borderBottom: 'unset', backgroundColor: '#F5F5F5' } }}
                  >
                    <TableCell component="th" scope="row" sx={{ width: '120px' }}>
                      <Switch color={'success'} disabled defaultChecked />
                    </TableCell>
                    <TableCell sx={{ width: '25%' }}>
                      <TextField
                        onChange={(e) => setTopicName(e.target.value)}
                        value={topicName}
                        label="Topic"
                        fullWidth
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={saveTopic}
                        sx={{ width: 130, marginLeft: 5, height: '100%' }}
                        variant="contained"
                      >
                        Add topic
                      </Button>
                      <Button
                        onClick={resetTopicInput}
                        sx={{ marginLeft: 6 }}
                        variant="outlined"
                        color="error"
                        startIcon={<CancelIcon />}
                      >
                        Cancel
                      </Button>
                    </TableCell>
                  </TableRow>
                  {topics &&
                    topics.map((topic: Topic) => (
                      <Row
                        key={topic.id}
                        inputTopic={topic}
                        fetchTopics={fetchTopics}
                        showToast={showToast}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography
              paragraph={true}
              sx={{ width: '100%', mt: 2 }}
              align="left"
              component="div"
              variant="h4"
            >
              Get started by adding a topic
            </Typography>
          )}
          <Snackbar
            key={'bottom'}
            open={openToast}
            autoHideDuration={5000}
            onClose={() => setOpenToast(false)}
          >
            <MuiAlert elevation={6} severity="success" variant="filled">
              {toastMessage}
            </MuiAlert>
          </Snackbar>
        </Grid>
      ) : (
        <NoAccess />
      )}
    </>
  );
};
